class BlockAccordion {
  constructor(targetDOM) {
    this.setParams(targetDOM)
    this.bindEvent()
  }
  setParams(targetDOM) {
    this.buttons = Array.prototype.slice.call(
      targetDOM.getElementsByClassName('js-block-accordion-trigger')
    )
    this.contents = Array.prototype.slice.call(
      targetDOM.getElementsByClassName('js-block-accordion-target')
    )
  }
  bindEvent() {
    this.buttons.forEach((element, index) => {
      element.addEventListener('click', e => {
        e.preventDefault()
        if(this.buttons[index].classList.contains('is-active')) {
          this.scrollPosition(index)
        }
        this.accordion(index)
      })
    })
  }
  accordion(index) {
    this.buttons[index].classList.toggle('is-active')
    this.contents[index].classList.toggle('is-active')
  }
  scrollPosition(index) {
    const targetHeight = this.contents[index].clientHeight
    const offsetTop = window.pageYOffset
    const top = offsetTop - targetHeight
    window.scrollTo({
      top,
      behavior: 'auto',
    })
  }
}
export default BlockAccordion
