import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'form' ]
  static values = { url: String }

  fetch(event) {
    const params = new URLSearchParams();
    Array.from(this.formTarget.querySelectorAll('input[name],select[name]')).forEach((e) => {
      if (!e.disabled && e.closest('[disabled]') === null && !params.getAll(e.name).includes(e.value)) {
        if ((e.type === 'checkbox' || e.type === 'radio')) {
          if (e.checked) {
            params.append(e.name, e.value)
          }
        } else if(e.type !== 'submit' && e.value) {
          params.append(e.name, e.value)
        }
      }
    })
    if (params.has('brand_id') && params.has('store_ids[]')) {
      params.delete('brand_id')
    }
    params.sort()
    const queryStr = params.toString()

    const frame = document.getElementById('total-count')
    if (frame) {
      frame.src = `${this.urlValue}${queryStr ? '?' + params.toString() : ''}`
    }
  }
}
