import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['parent', 'child']

  toggleAll(event) {
    const checked = event.currentTarget.checked
    this.childTargets.forEach(el => {
      el.checked = checked
    })
  }

  sync() {
    if (this.hasParentTarget) {
      this.parentTarget.checked = this.childTargets.every(el => el.checked);
    }
  }
}
