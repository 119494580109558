class AccessEffectScroll {
  constructor(targetDOM) {
    this.setParams(targetDOM)
    this.scrollEvent()
  }
  setParams(targetDOM) {
    this.SCROLLED = 'is-scrolled'
    this.target = targetDOM
    this.offset = this.target.offsetHeight;
  }
  scrollEvent() {
    window.addEventListener('scroll', () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > this.offset + 150) {
        this.target.classList.add(this.SCROLLED)
      } else {
        this.target.classList.remove(this.SCROLLED)
      }
    })
  }
}
export default AccessEffectScroll
