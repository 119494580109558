function importAll(r) {
  r.keys().forEach(r)
}

importAll(
  require.context(
    '../components/mobile',
    true,
    /[_\/]component\.((js)|(scss))$/
  )
)
