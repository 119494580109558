class ModalMenu {
  constructor(targetDOM) {
    this.setParams(targetDOM)
    this.bindEvent()
  }
  setParams(targetDOM) {
    this.STATE = 'is-show'
    this.MODAL_ROOT_CLOSE = 'is-modal-root-close'
    this.NO_SCROLL = 'u-noscroll'
    this.html = document.querySelector('html')
    this.body = document.querySelector('body')
    this.searchPanel = targetDOM
    this.items = Array.prototype.slice.call(
      this.searchPanel.querySelectorAll('.js-modal-menu-event-item')
    )
    this.overlay = document.querySelector('.js-modal-menu-overlay')
  }
  bindEvent() {
    this.items.forEach(element => {
      const trigger = element.querySelector('.js-modal-menu-trigger')
      const modal = element.querySelector('.js-modal-menu-content')
      const closeBtn = element.querySelector('.js-modal-menu-close')

      trigger.addEventListener('click', e => {
        e.preventDefault()
        this.html.classList.add(this.NO_SCROLL)
        this.body.classList.add(this.NO_SCROLL)
        modal.classList.add(this.STATE)
        this.overlay.classList.add(this.STATE)
      })
      closeBtn.addEventListener('click', e => {
        e.preventDefault()
        if (e.currentTarget.classList.contains(this.MODAL_ROOT_CLOSE)) {
          this.html.classList.remove(this.NO_SCROLL)
          this.body.classList.remove(this.NO_SCROLL)
          this.overlay.classList.remove(this.STATE)
        }
        modal.classList.remove(this.STATE)
      })
      this.overlay.addEventListener('click', e => {
        e.preventDefault()
        this.html.classList.remove(this.NO_SCROLL)
        this.body.classList.remove(this.NO_SCROLL)
        this.overlay.classList.remove(this.STATE)
        modal.classList.remove(this.STATE)
      })
    })
  }
}
export default ModalMenu
