class TabSwitcher {
  constructor(targetDOM) {
    this.setParams(targetDOM)
    this.bindEvent()
  }
  setParams(targetDOM) {
    this.target = targetDOM
    this.tabs = Array.prototype.slice.call(
      this.target.firstElementChild.children
    )
    this.panels = Array.prototype.slice.call(
      this.target.lastElementChild.children
    )
    this.tabScrolls = Array.prototype.slice.call(document.getElementsByClassName("js-tab-scroll-position"))
  }
  bindEvent() {
    this.tabs.forEach((element, index) => {
      element.addEventListener('click', e => {
        this.tabSwitch(index)
      })
    })
    this.tabScrolls.forEach((element, index) => {
      if(element.classList.contains('is-active')) {
        element.parentNode.scrollLeft = 250;
      }
    })
  }
  tabSwitch(index) {
    for (let element of this.tabs) {
      element.classList.remove('is-active')
    }
    for (let element of this.panels) {
      element.classList.remove('is-show')
    }
    this.tabs[index].classList.toggle('is-active')
    this.panels[index].classList.toggle('is-show')
  }
}
export default TabSwitcher
