import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form']

  remove(event) {
    const input = this.formTarget.querySelector(`input[type="hidden"][name="${event.params.name}"][value="${event.params.value}"]`)
    if (input) {
      input.disabled = true
      this.formTarget.requestSubmit()
    }
  }
}
